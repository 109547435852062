export enum FleetRoles {
  ADMIN = "ADMIN",
  MANAGER = "MANAGER",
  STAFF = "STAFF",
  ALL = "ALL",
}

/**
 * @public
 */
export const FleetRoleLabels: Record<FleetRoles, string> = {
  [FleetRoles.ADMIN]: "Account Admin",
  [FleetRoles.MANAGER]: "Fleet Manager",
  [FleetRoles.STAFF]: "Staff",
  [FleetRoles.ALL]: "", // empty string means this will not show up in UI
};

export enum PaymentType {
  CREDIT_CARD = "CREDIT CARD",
  INVOICING = "INVOICING",
  MANAGEMENT_COMPANY = "FMC",
}

export enum InvoiceType {
  DUE_ON_INVOICE = "DUE ON INVOICE",
  NET_30 = "NET 30",
  AS_INVOICED = "AS INVOICED",
}

export enum ManagementCompanies {
  ARI = "ARI",
  DONLEN = "Donlen",
  EFLEETS = "eFleets",
  ELEMENT = "Element",
  EMKAY = "EMKAY",
  FLEET_RESPONSE = "Fleet Response",
  FLEETIO = "Fleetio",
  HOLMAN = "Holman",
  LEASE_PLAN = "LeasePlan",
  MERCHANT = "Merchant",
  MIKE_ALBERT = "Mike Albert",
  WHEELS = "Wheels",
  COX = "Cox Automotive",
  OTHER = "Other",
}

export interface Fleet {
  id: number;

  name: string;

  skipEstimate: boolean;

  company?: string | null;

  corporateCode?: string | null;

  paymentType: PaymentType;

  managementCompany?: string | null | ManagementCompanies;
  managementCompanyLink?: string | null;

  stripeId?: string | null;

  billingContact?: string | null;

  billingAddress?: string | null;

  billingEmail?: string | null;

  city?: string | null;

  state?: string | null;

  zip?: string | null;

  website?: string | null;

  fleetType?: string | null;

  taxExemption?: boolean | null;

  phone?: string | null;

  insuranceCompany?: string | null;

  policyNumber: string | null;

  claimsEmail?: string | null;

  fleetSize?: number | null;

  isInsuranceFleet: boolean;

  cbChargeAccountNumber?: string | null;

  defaultLotId: number | null;
  isInstantPayAvailable: boolean;

  rebateRate: number;

  shopMonkeyId?: string;
}

export interface FleetInvite {
  id: number;

  createdAt: string;

  acceptedAt: string | null;

  email: string;

  fleetId: number;

  revokedAt: string;

  role: FleetRoles;
}

export interface InvitedFleetUser {
  id: number;
  createdAt: string;
  acceptedAt: string | null;
  email: string;
  fleetId: number;
  revokedAt: string;
  role: FleetRoles;
}

export interface AcceptFleetInviteForm {
  invite: string;

  signature: string;

  email: string;

  fleetId: number;

  inviteId: number;

  phone: string;

  isInsurance?: boolean;
}

export interface FleetVehicle {
  vehicleId: number;

  fleetId: number;

  nickname: string | null;

  addedAt: string;

  deletedAt: string | null;

  customImageUrl: string | null;

  notes?: string | null;
}
