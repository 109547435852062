import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
interface ReactNativeWebView {
  postMessage: (message: string) => void;
}
export const isReactNativeWebview = (
  window: unknown
): window is Window & { ReactNativeWebView: ReactNativeWebView } => {
  if (typeof window !== "object" || window === null) {
    return false;
  }

  return (
    "ReactNativeWebView" in window &&
    typeof window.ReactNativeWebView === "object" &&
    window.ReactNativeWebView !== null &&
    "postMessage" in window.ReactNativeWebView &&
    typeof window.ReactNativeWebView.postMessage === "function"
  );
};

interface CustomMessageEvent extends MessageEvent {
  data: string;
}
export const handleRefreshMessage = (
  event: CustomMessageEvent,
  client: ApolloClient<NormalizedCacheObject>
) => {
  if (event.data === "refresh") {
    client.refetchQueries({
      include: "active",
      onQueryUpdated: (observableQuery) => {
        const result = observableQuery.refetch();
        if (isReactNativeWebview(window)) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              messageType: "refreshComplete",
            })
          );
        }
        return result;
      },
    });
  }
};
